export default {
	cinema: {
		address: 'ул. Тевосяна, д. 26а',
		place: null,
		phoneNumbers: ['8 (495) 127-02-34', '8 (496) 573-76-66', '8 (926) 117-20-17'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/club17223564',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: 'https://itunes.apple.com/ru/app/современник-билеты-в-кино/id1248779814',
			googleplay: 'https://play.google.com/store/apps/details?id=ru.kinoplan.cinema.sovremennik',
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBQbITrVcD',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
	},
	feedback: true,
};
