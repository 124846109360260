import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '189, 49, 39',
		'primary-dark': '127, 20, 13',
		'accent': '255, 226, 0',
		'accent-plus': '0, 0, 0',
	},
});
